import React from 'react'
import clsx from 'clsx'

import { Link } from 'gatsby-theme-material-ui'

import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import Routes from '@root/routes'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    marginTop: theme.spacing(-3),
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(-3),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.drawerWidth})`,
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    minWidth: '50%',
    padding: theme.spacing(2),
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.secondary.light,
    },
    '&:first-of-type': {
      borderRight: '1px solid white',
    },
  },
}))

const Pagination = ({ previous, next }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <PreviousPage to={previous} />
      <NextPage to={next} />
    </div>
  )
}

const PreviousPage = ({ to }) => {
  return (
    <>
      {to && (
        <PageLink to={to}>
          <ArrowBackIcon fontSize="small" /> précédent
        </PageLink>
      )}
    </>
  )
}

const NextPage = ({ to }) => {
  const url = to ? to : Routes.CURRICULUM
  const label = to ? 'suivant' : 'retour au curriculum'

  return (
    <PageLink to={url}>
      {label} <ArrowForwardIcon fontSize="small" />
    </PageLink>
  )
}

const PageLink = ({ to, children }) => {
  const classes = useStyles()

  return (
    <Link className={classes.link} to={to}>
      {children}
    </Link>
  )
}

export default Pagination
