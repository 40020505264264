import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Typography from '@material-ui/core/Typography'

import SEO from '@components/seo'
import Layout from '@components/layout'
import Page from '@components/Page'
import Pagination from '../components/pagination'
import LectureDuration from '@components/LectureDuration'

import useProgressTracker from '@hooks/progressTracker'

export default function Template({
  data,
  pageContext: { previous, next, breadcrumbs },
}) {
  const { mdx } = data
  const { frontmatter, body } = mdx

  const { track } = useProgressTracker()

  useEffect(() => {
    track(frontmatter.slug)
  }, [])

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <SEO title={frontmatter.title} />

      <Page>
        <LectureDuration duration={frontmatter.time} />

        <Typography variant="h1">{frontmatter.title}</Typography>

        <MDXRenderer>{body}</MDXRenderer>
      </Page>

      <Pagination previous={previous} next={next} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        slug
        title
        type
        time
      }
    }
  }
`
