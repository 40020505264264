import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  page: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(8),
  },
}))

const Page = ({ children }) => {
  const styles = useStyles()

  return <div className={styles.page}>{children}</div>
}

export default Page
