import React from 'react'

import Indicateur from '@components/indicateur'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  lectureDuration: {
    [theme.breakpoints.down('md')]: {
      float: 'none',
      display: 'flex',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      float: 'right',
    },
  },
}))

const LectureDuration = ({ duration }) => {
  const styles = useStyles()

  return (
    <div className={styles.lectureDuration}>
      {duration && (
        <Indicateur type="TEMPS_TRAVAIL">Temps estimé: {duration}</Indicateur>
      )}
    </div>
  )
}

export default LectureDuration
