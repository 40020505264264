import React, { useEffect, useState } from 'react'

const getCurrentProgress = () => {
  return JSON.parse(localStorage.getItem('progress')) || {}
}

const useProgressTracker = () => {
  const [progress, setProgress] = useState({})

  useEffect(() => {
    const p = getCurrentProgress()
    setProgress((state) => ({ ...state, ...p }))
  }, [])

  useEffect(() => {
    const p = JSON.stringify(progress)
    p && p !== '{}' && localStorage.setItem('progress', p)
  }, [progress])

  const track = (lecture) => {
    setProgress((state) => ({
      ...state,
      [lecture]: true,
    }))
  }

  return { track, progress }
}

export default useProgressTracker
